.MuiGrid-root {
  &.form-designer-page-root {
    width: 100%;
    margin-left: 0px;

    .form-designer-page-root__item {
      padding: 0px 0px;
    }

    .form-designer-page-root__item:first-child {
      padding-right: 20px;
      > div.MuiPaper-root {
        top: 16px;
        position: sticky;
      }
    }
  }
}
.form-designer-button-container {
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 10px;
  }
}
