.top-panel-config {
  .input {
    width: 20%;
  }
}

.mgt-dark.e365-dark {
  background-color: transparent;
}

.card-container:has(.content-card.expanded) {
  .content-card {
    opacity: 0.5;
  }

  .content-card.expanded {
    opacity: 1;
  }
}
