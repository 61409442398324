.full-news-root {
  .title-aligner {
    align-items: center;
  }

  .title-container {
    justify-content: space-between;
    padding: 1em 1em 0 1em;
  }
  .content-container {
    padding: 0 1em 1em 1em;
  }
}
