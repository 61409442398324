.review-stage-approval-root {
  margin-top: 1em;

  .button-container {
    justify-content: flex-end;
  }

  .current-stage {
    padding: 8px 16px;
  }
}
