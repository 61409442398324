.avatar-wrapper {
  margin: 0 5px;
}

.edison-peoplegroup-root {
  display: flex;
  align-items: center;
}

mgt-get {
  margin-left: 0px !important;
}

mgt-people {
  div {
    display: flex;
  }
}

.personCardHover {
  margin-left: 1px;
  margin-top: -148px;
  position: absolute;
  z-index: 999;
}
.personCardHoverLeft {
  margin-left: -184px;
}

mgt-person.avatar {
  margin-right: -10px;
}

mgt-person.avatar-large {
  --person-avatar-size: 40px;
}

mgt-person.avatar-auto {
  --person-avatar-size: 32px;
}

mgt-person.avatar-small {
  --person-avatar-size: 24px;
}

.popover-paper {
  min-height: 575px; /* max height of the persona card */
  height: 75%;
  background-color: transparent !important;
  background-image: none !important;
  box-shadow: none !important;
}
