.approvals-task-subtable-root {
  .items {
    width: 76%;
  }

  .complete {
    width: 12%;

    .MuiCheckbox-root {
      padding: 0;
    }
  }

  .status {
    width: 12%;
  }

  .MuiTableHead-root {
    .MuiTableCell-root {
      font-weight: 700;
      border-top: none;
    }
  }
}
