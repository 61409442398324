.edison-people-picker-popper-root {
  z-index: 500;
  .MuiAutocomplete-listbox {
    padding-top: 0;
  }
  .MuiListSubheader-root {
    padding: {
      top: 10px;
      bottom: 6px;
    }
  }
}
.avatar-group-root {
  width: fit-content;
}

.edison-people-picker-root {
  mgt-people-picker {
    --input-border: none;
    --input-border-bottom: 1px solid;
    --input-background-color: transparent;
  }
  mgt-people-picker.disabled {
    --people-picker-remove-selected-close-icon-color: transparent;
  }
}
