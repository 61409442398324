.MuiGrid-root {
  &.features-container {
    .drag-drop-container {
      align-self: center;
      .drag-drop-heading {
        opacity: 0.6;
      }
    }
    .tab-container {
      width: 100%;
      height: 95vh;
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
}
