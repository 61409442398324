@import "../../../theme/colors.scss";

.usercomponent-parent {
  .usercomponent {
    height: auto;
    padding: 10px;
    cursor: pointer;
    width: 100%;
    align-items: center;
  }

  .pointer {
    margin-top: 25px;
  }
  .hoverIcons {
    visibility: hidden;
  }
}
