.create-record-type-root {

  .type-item {
    display: flex;
    min-width: 14em;
    cursor: pointer;
    align-items: center;
    padding: 0.5em;
    position: relative;
    $border: 0.25em;
    border: solid $border transparent;
    border-radius: 16px;
    box-sizing: border-box;

    &:hover {
      background-clip: padding-box !important;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: - calc($border - 0.05em);
      border-radius: inherit;
      background: linear-gradient(var(--EdisonGradient));
    }
  }
}

.create-record-type-root ~ .msg-no-templates {
  display: none;
}

.create-record-type-root:empty ~ .msg-no-templates {
  display: flex;
}
