.rich-text-field-toolbar-root {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  align-items: center;

  .font-size-picker {
    margin-left: 1em;
    width: 4em;
  }
}
