.node-title-root {
  padding: 2px 0 2px 1px;
  border-radius: 6px 6px 0 0;
  .node-icon {
    display: flex;
    align-items: center;
  }

  .node-title-container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .node-title {
      margin-left: 1em;
      display: flex;
      align-items: center;
    }

    .delete-button {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
