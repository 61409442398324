.owned-items-root {
  padding: 1em;

  .owned-items-table {
    thead th{
      background-color: var(--tableRowBackgroundColorOdd);
      font-weight: bold;
    }
    .tasks-link {
      color: var(--Main);
    }
    .action {
      width: 150px;
    }
  }
}
