.workflow-live-preview-tabs-root {
  padding: 10px 20px;
  justify-content: space-between;
  .workflow-tab-container {
    justify-content: space-between;
    .single-tab {
      align-items: center;
      padding: 8px;
      cursor: pointer;
    }

    .tab-navigation-container {
      align-items: center;
      .arrow-button {
        height: fit-content;
        &.active {
          color: var(--Main);
        }
      }
    }
  }
}
