.condition-zone-root {
  background-color: var(--BackdropOverlay);
  padding: 1em;
  border: 1px dashed var(--CFFunctions);
  border-radius: 4px;
  flex-grow: 1;

  .result-block {
    padding-left: 1em;
    border-radius: 4px;

    &.nested {
      padding: 1em;
      flex-grow: 1;
    }

    .capitalized {
      color: var(--CFFunctions);
      text-transform: uppercase;
    }
  }
}
