.filterWrapper {
  padding: 1em;
  max-width: 100%;
  min-width: 700px;

  border-radius: 12px;

  .editFilterButton {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.4px;
    border-radius: 8px;
    height: 34px;
  }

  .generateReport,
  .addFilterButton {
    border-radius: 8px;
    height: 34px;
  }

  .accordionSummary {
    width: 100%;
  }

  .accordionDetails {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
  }

  .filter-info {
    display: flex;
    align-items: center;
    column-gap: 10px;
    height: 45px;
    padding: 0 12px;
    border-radius: 4px;
  }

  .date-range-text {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
  }
}
