.expression-zone-root {
  width: 100%;
  align-content: flex-start;
  background-color: var(--BackdropOverlay);
  padding: 2em 1em 2em 1em;
  border-radius: 16px;

  .equals-container {
    display: flex;
    align-items: center;
    margin-right: 1em;
  }
  .expression-part-container {
    margin-right: 2px;
    align-items: center;
  }
  .end-dropzone {
    flex-grow: 1;
    cursor: pointer;
  }
}
