@import "./../../../../../../theme/bryntumcustomtheme.scss";

.timeranges-dropdown-root {
  .dropdown-container {
    justify-content: space-between;

    .title-container {
      align-items: center;
    }
  }
  .timeranges-table {
    .MuiTableCell-root {
      padding: 0;
      border-bottom: 0;
    }
  }
}
