.toolbar-root {
  justify-content: space-between;
  padding: 5px;

  .icon-button {
    height: 36px;
    aspect-ratio: 1;
    min-width: 36px;
    z-index: 1;
    border-radius: 10px;
  }
}

.task-table-calendar-modal-root {
  padding: 0.8em;
  width: 26em;

  .align-center {
    align-items: center;
  }
}
