.user-phone-container-hide {
  display: none;
  font-size: 16;
  font-weight: 400;
  letter-spacing: 0.15;
}

.user-phone-field-root {
  &.in-table {
    width: 100%;
    height: 100%;
    font-size: 16;
    font-weight: 400;
    letter-spacing: 0.15;

    .readonly {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-size: 16;
      font-weight: 400;
      letter-spacing: 0.15;
    }
  }

  .user-phone-container {
    &.react-tel-input {
      position: static;
      font-size: 16;
      font-weight: 400;
      letter-spacing: 0.15;
    }

    .user-phone {
      border: 2px solid inherit;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      font-size: 16;
      font-weight: 400;
      letter-spacing: 0.15;

      &:focus {
        border: 2px solid #1976d2;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        font-size: 16;
        font-weight: 400;
        letter-spacing: 0.15;
      }

      &.flag-dropdown {
        position: static;
        margin-top: -20px;
        font-size: 16;
        font-weight: 400;
        letter-spacing: 0.15;
      }
    }

    .phone-drop-down {
      position: absolute;
      font-size: 16;
      font-weight: 400;
      letter-spacing: 0.15;
    }

    .user-phone-full-width {
      width: 100%;
      font-size: 16;
      font-weight: 400;
      letter-spacing: 0.15;
    }

    .user-phone-readonly {
      cursor: default;
      border-bottom: 0;
      font-size: 16;
      font-weight: 400;
      letter-spacing: 0.15;
    }

    .form-control {
      height: 23px;
      background: none;
      font-size: 16;
      font-weight: 400;
      letter-spacing: 0.15;

      &:focus {
        box-shadow: none;
        font-size: 16;
        font-weight: 400;
        letter-spacing: 0.15;
      }
    }

    .search {
      width: calc(100% - 15px);
      margin-left: 3px;
      font-size: 16;
      font-weight: 400;
      letter-spacing: 0.15;

      .search-box {
        width: 100%;
        margin-left: 0;
        border-radius: 8px;
        height: 32px;
        font-size: 16;
        font-weight: 400;
        letter-spacing: 0.15;
      }
    }
  }

  .phone-drop-down {
    position: relative;
    font-size: 16;
    font-weight: 400;
    letter-spacing: 0.15;
  }
}
