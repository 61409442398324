.carousel-container {
  padding: 1em;

  /* width */
  &::-webkit-scrollbar {
    height: 10px;
    padding: 5px 0 5px 0;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &.dark {
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #fff;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #ccc;
    }
  }

  .entry-container {
    .embla-slide {
      flex: 0 0 100%;
    }

    .isSelectable {
      cursor: pointer;
    }

    .isSelected {
      box-shadow: 0px 0px 6px 2px #bdbdbd;
      border-radius: 15px;
    }

    .carousel-loading-container {
      width: 8em;
      height: 100%;
      display: flex;

      img {
        width: 8em;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .entry-container:last-child {
    padding-right: 1em;
  }

  .navigation-container {
    justify-content: flex-end;
    width: 100%;
    padding-top: 20px;
  }
}
