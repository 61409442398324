@import "./../../theme/bryntumcustomtheme.scss";

.schedulerpro-root {
  margin-bottom: 6px;
  .b-sch-event:not(.b-milestone),
  .b-sch-event:hover:not(.b-milestone),
  .b-sch-selected:not(.b-milestone) {
    background-color: var(--schedulerTaskColor);
    border-color: var(--schedulerTaskColor);
    border-radius: 12px;
  }

  .b-widget.b-timeline {
    flex: none;
    height: 7em;

    .b-grid-panel-body {
      padding: 0em 5em 2em 5em;
    }

    label::before {
      display: none;
    }

    .b-widget-cell {
      border-radius: 30px;
      margin: 1px;
    }

    .b-timeline-startdate,
    .b-timeline-enddate {
      font-size: small;
    }

    .b-sch-header-text {
      font-size: small;
    }
  }

  .event-base-text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  .event-name {
    @extend .event-base-text;
    font-size: 10px;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: rgba(0, 0, 0, 0.6);
  }

  .project-name {
    @extend .event-base-text;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    align-items: center;
    gap: 4px;

    svg {
      fill: #f44336;
      height: 20px;
    }
  }
}
