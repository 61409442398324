.imageField {
  height: 100%;
  display: flex;
  flex-direction: column;
  .root {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%232e81f8' stroke-width='2' stroke-dasharray='10%2c 10' stroke-dashoffset='7' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }

  .root.no-border {
    background-image: none;
  }

  .dropzone {
    font-size: 1.25em;
    min-height: 75px;
    min-width: 100px;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 20px;
    cursor: pointer;
  }
  .fileInputContainer {
    align-items: center;
    justify-content: center;
    padding: 2em;
  }
  .form-helper-styling {
    height: 1em;
    margin-bottom: 40px;
    p {
      font-size: 16px;
    }
  }
  .thumbContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 0;
    max-width: 350px;
    width: auto;
    height: auto;
    padding: 4px;
    box-sizing: border-box;
  }
  .thumbInner {
    width: 100%;
    overflow: hidden;
  }
  .img {
    width: 100%;
    height: auto;
  }
  .imgDisabled {
    opacity: 0.6;
  }
  .thumbAction {
    width: 20px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }
  .actionButton {
    cursor: pointer;
    width: 100%;
    height: 20px;
  }
  .editButton {
    @extend .actionButton;
  }
  .deleteButton {
    @extend .actionButton;
  }

  .toolbar {
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }
  .divider-container {
    padding: 10px 0;
  }
  .display-image {
    height: 100%;
  }
}
