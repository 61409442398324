@import "@bryntum/gantt-thin/gantt.material.css";
@import "@bryntum/core-thin/core.material.css";
@import "@bryntum/grid-thin/grid.material.css";
@import "@bryntum/scheduler-thin/scheduler.material.css";
@import "@bryntum/schedulerpro-thin/schedulerpro.material.css";
@import "./colors.module.scss";

.enada-bryntum-grid,
.fieldclass {
  .b-panel {
    border-radius: 8px;
  }

  .user-table-cell {
    display: flex;
    align-items: flex-start;
  }
  .edisonText {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--tableTextColor);
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  .b-react-portal-container {
    width: 100%;
    min-height: 100%;
    display: flex;
  }

  // Removes the unchecked tick from the checboxes
  .b-checkbox {
    --checkbox-unchecked-check-color: transparent !important;
  }

  .b-grid-subgrid .b-grid-row .b-grid-cell,
  .b-editor .b-field .b-field-inner,
  .b-grid-footer {
    @extend .edisonText;
  }

  .b-grid-header-container {
    .b-grid-headers {
      .b-check-header-with-checkbox {
        min-width: 56px !important;
        flex-basis: 56px !important;
      }
    }
  }

  .b-grid-header-text > .b-grid-header-text-content,
  .b-sch-header-timeaxis-cell,
  .b-gridbase:not(.b-masked) .b-grid-empty .b-grid-subgrid:first-child::before {
    @extend .edisonText;
    font-weight: 600;
    line-height: 24px;
  }

  .b-grid-header-container .b-grid-headers,
  .b-timeaxis .b-sch-header-row,
  .b-grid-body-container,
  .b-grid-panel-body,
  .b-taskboard-body,
  .b-header .b-grid-headers .b-focused,
  .b-empty-text,
  .b-grid-footer {
    box-shadow: none;
    background-color: var(--tableBackgroundColor) !important;
  }

  .b-grid-row:nth-child(odd):not(.b-selected, .b-hover) > .b-grid-cell:not(.b-timeaxis-cell) {
    background: var(--tableRowBackgroundColorOdd) !important;
  }

  .b-grid-row:nth-child(even):not(.b-selected, .b-hover) > .b-grid-cell:not(.b-timeaxis-cell) {
    background: var(--tableRowBackgroundColorEven) !important;
  }

  .b-selected,
  .b-hover,
  .b-selected .b-grid-cell.b-check-cell,
  .b-hover .b-grid-cell.b-check-cell {
    background-color: var(--rowSelected) !important;
  }

  .b-sch-header-row .b-sch-header-timeaxis-cell:hover,
  .b-grid-headers .b-grid-header:hover {
    background-color: var(--rowSelected) !important;
  }

  .b-grid-row .b-editing {
    overflow: hidden;
  }

  .b-grid-row .system-cell-editor,
  .b-editor.b-cell-editor.b-content-element.b-fit-container {
    background-color: var(--rowSelected);
  }

  .b-header .b-grid-headers .b-grid-header.b-resizing {
    background: var(--tableRowBackgroundColorEven);
  }

  .b-sch-dependency {
    stroke-width: 2;
    stroke: var(--taskColor);
    stroke-linejoin: round;
  }

  .b-sch-dependency-arrow {
    fill: var(--taskColor);
  }

  /* Resource Histogram & Utilization */
  .b-sch-timeaxis-cell {
    .b-histogram {
      rect {
        fill: var(--resourceUtilizationColor);
      }

      rect.b-overallocated {
        fill: var(--resourceUtilizationOverallocatedColor);
      }
    }
  }

  /* Taken from https://forum.bryntum.com/viewtopic.php?p=61845 */
  /* HEADER STYLES */
  .b-grid-headers {
    .b-sch-timerange {
      &.b-sch-nonworkingtime {
        background-color: var(--tableWeeekendHeaderColor);
      }
    }
  }

  /* BODY STYLES */
  .b-grid-subgrid {
    .b-sch-range {
      &.b-sch-nonworkingtime {
        background-color: var(--tableWeekendBodyColor);
      }
    }
  }

  // add font awesome 6 free to the indicator icons
  // this should work ootb with Brymtum but for some reason doesn't
  .b-indicator {
    &::before {
      font-family: "Font Awesome 6 Free";
    }
  }
}

/* Styling schedule table task editor popup*/
.enada-bryntum-editor {
  background-color: var(--tableRowBackgroundColorOdd);

  .b-panel-header {
    background-color: var(--rowSelected);
    color: var(--tableTextColor);

    .b-header-title {
      font-size: 18px;
    }

    button {
      color: var(--tableTextColor);
    }

    .b-popup-close {
      font-size: 18px;
    }
  }

  /* editor tabs colors*/
  .b-container.b-toolbar.b-tabbar.b-dock-top {
    background-color: var(--tableRowBackgroundColorOdd);
  }

  /* editor tab body styling*/
  .b-fit-container-panel.b-panel-body-wrap.b-taskeditor-body-wrap {
    background-color: var(--tableRowBackgroundColorOdd);

    .b-panel-body-wrap.b-tabpanel-body-wrap {
      background-color: var(--tableRowBackgroundColorOdd);
    }

    .b-panel-content {
      margin: 6px;
      background-color: var(--tableRowBackgroundColorOdd);

      .b-textfield,
      .b-numberfield,
      .b-label {
        color: var(--tableTextColor);
      }

      .b-tabpanel-tab {
        border-bottom: 0;
      }

      .b-tabpanel-tab.b-active {
        background-color: var(--rowSelected);
        color: var(--tableTextColor);
      }

      .b-tabpanel-tab:hover:not(.b-active):not(.b-disabled),
      .b-tabpanel-tab:focus:not(.b-active):not(.b-disabled),
      .b-tabpanel-tab.b-contains-focus {
        opacity: 50%;
        background-color: var(--rowSelected);
        color: var(--tableTextColor);
      }

      .b-toolbar > .b-tool {
        background-color: var(--rowSelected);
        color: var(--tableTextColor);
      }
    }
  }

  /* editor bottom bar styling*/
  .b-bottom-toolbar {
    background-color: var(--tableRowBackgroundColorOdd);

    .b-blue {
      background-color: var(--primary-color);
    }

    button {
      border-radius: 8px;
      min-width: 100px;
      min-height: 36px;
      height: 36px;
    }
  }
}

.enada-bryntum-popup-menu,
.b-sub-menu {
  .b-panel-content {
    background-color: var(--tableBackgroundColor);
  }

  .b-menuitem {
    background-color: var(--tableBackgroundColor);
    color: var(--tableTextColor);

    &:hover {
      background-color: var(--rowSelected);
    }
  }

  .b-menuitem.b-contains-focus,
  .b-menuitem:focus {
    background-color: var(--rowSelected);
  }

  .b-menuitem.b-disabled:hover,
  .b-menuitem.b-disabled.b-contains-focus,
  .b-menuitem.b-disabled:focus {
    color: var(--tableTextColor);
    background-color: var(--tableBackgroundColor);
  }
}
