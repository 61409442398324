.expression-unit-root {
  width: fit-content;
  height: 100%;
  align-items: center;

  .dropzone {
    width: 1em;
    cursor: pointer;
    height: 100%;
  }

  .entity-container {
    padding: 2px;

    .unit {
      border: 1px solid transparent;
      cursor: pointer;
      border-radius: 3px;
      padding: 0.5em;

      &.selected {
        border: 1px solid;
        cursor: grab;
      }
    }

    .operator {
      color: var(--CFOperators);
    }

    .operand {
      color: var(--CFFields);
    }

    .delete-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .custom-value {
      color: var(--SuccessMain);
      cursor: pointer;
      padding: 0.5em;
      height: 100%;

      &.selected {
        padding: 0;
      }

      .MuiInputBase-inputSizeSmall {
        color: var(--SuccessMain);
      }

      fieldset {
        border-color: var(--SuccessMain);
      }
    }
    .delete-icon {
      cursor: pointer;
      font-size: x-small;
      margin-left: 1px;

      &.hidden {
        visibility: hidden;
      }

      &.visible {
        visibility: visible;
      }

      &.custom {
        color: var(--SuccessMain);
      }
    }
  }
}
