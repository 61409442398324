@import "../../theme/colors.module.scss";

.input {
  font-size: 13px;
}

@mixin placeholderStyle() {
  text-transform: uppercase;
  opacity: 0.6;
}

.search-placeholder {
  ::-webkit-input-placeholder {
    @include placeholderStyle();
  }

  :-moz-placeholder {
    @include placeholderStyle();
  }

  ::-moz-placeholder {
    @include placeholderStyle();
  }

  :-ms-input-placeholder {
    @include placeholderStyle();
  }
}

.searchbar-root {
  height: 32px;

  & .MuiOutlinedInput-root {
    border-radius: 8px;
  }

  legend {
    width: 0px;
  }
}
