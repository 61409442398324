.collapsible-row-root {
  .collapsible-row-cell {
    &.MuiTableCell-root {
      padding: 0;
    }

    .row-title {
      background-color: var(--tableRowBackgroundColorOdd);
      padding: 16px 0 16px 0;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
