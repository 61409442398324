.table-config-root {
  padding: 1em;

  .title-container {
    justify-content: space-between;
  }

  .field-selector {
    .fields-list {
      height: calc(100vh - 38px);
      max-height: 100%;
      overflow-y: auto;
      position: sticky;
      top: 16px;
      padding: 10px 20px 10px 0px;
    }

    max-width: 32%;
    flex-basis: 32%;
  }

  .selected-fields {
    flex-grow: 1;
  }

  .period-select {
    width: 20em;
  }

 .currency-container {
    align-items: center;

    .currency-select {
      width: 30em;
    }
  }

  .alert {
    cursor: pointer;
    flex-grow: 4;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .alert.closed {
    width: fit-content;
  }
  .table-personal-behaviour-enum-select {
    width: 100%;
  }
}
