@import "../../../theme/colors.scss";

.custom-workflow-node-root {
  cursor: pointer;

  &.outlined {
    box-shadow: 0 0 0.95em 0.25em $Main;
  }

  &.error {
    box-shadow: 0px 0px 8px 3px $No;
  }

  .node-metadata {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1em;

    .label-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .icon-button {
      font-size: small;
    }
  }
}
