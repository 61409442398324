@import "../../theme/colors.scss";

.history-item-container {
  display: flex;
}

.user {
  --people-list-margin: 0;
  --person-avatar-size: 24px;
  --person-details-wrapper-width: auto;
}

.history-item-icon > svg {
  color: var(--ActionActive);
}

div.history-item-icon {
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--HistoryIconColor);
  overflow: visible;
  position: relative;
}

.history-item-icon:after {
  content: "";
  background-color: var(--HistoryIconColor);
  position: absolute;
  bottom: 0;
  height: 183%;
  width: 1.5px;
  top: 1.5rem; /*center lines vertically*/
  z-index: -1;
}

.history-item-container:last-child .history-item-icon:after {
  display: none;
}

.filterIconHistory {
  position: absolute;
  left: 460px;
  cursor: pointer;
  visibility: hidden;
}
