.news-config-root {
  .card-configuration {
    padding: 1em;

    .news-config-content {
      .content {
        width: 50%;
      }
    }
  }

  .article-configuration {
    padding: 1em;
  }
}

.news-top-panel-container {
  width: 100%;
  .input {
    flex-grow: 1;
  }
}
