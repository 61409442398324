.calculated-config-root {
  height: 100%;
  max-height: 100%;
  width: 100%;
  padding: 1em;

  .format-container {
    align-items: center;
  }

  .fields-list {
    max-height: 40em;
    overflow-y: scroll;
  }

  .calculated-expression-builder {
    width: 100%;

    .toolbar {
      justify-content: space-between;
      padding: 1em;

      .operator {
        &.MuiButton-root {
          min-width: 3em;
          min-height: 0;
          font-weight: bolder;
        }
      }
    }
  }
}
