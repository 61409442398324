.main-grid-root {
  height: 100%;

  .main-grid-item-spacing {
    padding-left: 280px;
    transition: padding-left ease-out 0.3s;
  }

  .main-grid-item-spacing-nav-closed {
    padding-left: 118px;
    transition: padding-left ease-in 0.3s;
  }

  .main-grid-nav-paper {
    width: 250px;
    max-width: 100%;
    height: 96vh;
    position: fixed;
    transition: width ease-out 0.3s;
  }

  .main-grid-nav-paper-closed {
    width: 88px;
    max-width: 100%;
    height: 96vh;
    position: fixed;
    transition: width ease-in 0.3s;
  }

  .main-grid-padding {
    padding: 15px;
  }

  .main-grid-header {
    height: 70px;
  }
}
.underline-on-hover:hover {
  text-decoration: underline;
  cursor: pointer;
}
