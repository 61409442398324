.rich-text-config-root {
  .format-container {
    align-items: center;
  }
  .bounds-container {
    justify-content: space-between;
    .input {
      margin-top: 10px;
      width: 195px;
    }
  }
  .display-as-icon-container {
    align-items: center;
  }
}
