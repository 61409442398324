.edison-tabs {
  button.edison-tabs-tab {
    > div > span {
      white-space: nowrap;
    }
    .edison-typography {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
    }
  }
}

.edison-tabs-wide button.edison-tabs-tab {
  max-width: 470px;
}
