.filterWrapper {
  .rowPropertyBase {
    height: 44px;
  }

  .filterSelectorRow {
    .deleteAction {
      width: 5%;
      color: #d32f2f;
    }

    .propertyName,
    .selectedValue,
    .selectedMultiValue,
    .selectedMultiValuePeoplePicker,
    .selectedValueTextField,
    .dateWrapper {
      width: 35%;
    }
    .innerOperation {
      width: 25%;
    }
    .propertyName > div,
    .innerOperation > div,
    .selectedValue > div,
    .selectedMultiValue > div > div,
    .selectedValueTextField > div,
    .dateWrapper > div > div {
      @extend .rowPropertyBase;
    }

    .selectedMultiValuePeoplePicker > div > div {
      min-height: 44px;
    }

    .dateWrapper > div {
      width: 100%;
    }

    .selectedMultiValue {
      .tags-container {
        height: 100%;
        overflow: hidden;

        div {
          display: inline-flex;
        }
      }
    }

    .tag-limit {
      text-align: left;
    }
  }

  & .MuiPaper-root {
    box-shadow: none;
  }

  .addFilterButton {
    background-color: #2e81f8;
  }

  .autocomplete-dropdown-chip-wrapper > div {
    height: 30px;
  }
}
