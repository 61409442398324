@import "./../../../theme/colors.module.scss";

.MuiTableCell-head {
  padding: 7px 16px;
}

.MuiTableRow-root {
  &.rollup-table-row-root {
    &.selected {
      background-color: $rowHighlight;
    }

    .MuiTableCell-root {
      padding: 5px 16px;
    }

    .disabled {
      .MuiSvgIcon-root {
        color: var(--iconDisabled);
      }
    }
    &:not(:last-child) {
      .MuiTableCell-root {
        /*Needed to remove double thickness of rows*/
        border-bottom: unset;
      }
    }
  }
}
