.baselines-dropdown-root {
  .dropdown-container {
    justify-content: space-between;

    .title-container {
      align-items: center;
    }
  }

  .baselines-table {
    .MuiTableCell-root {
      padding: 0;
      border-bottom: 0;

      &.header {
        margin-left: 1em;

        &:nth-child(4) {
          line-height: 18px;
        }
      }
    }
  }
}
