@import "../../theme/colors.scss";

.livepreview {
  .tabs {
    .lp-tab {
      border-radius: 4px;
      border: 2px solid $adminColor30;
      padding: 0.2rem 0.3rem 0.1rem 0.3rem;
      background-color: $adminColor30;

      &.selected {
        border: 2px solid $primaryColor;
      }

      .lp-tab_header {
        flex-wrap: nowrap;

        .cancel-icon {
          @include pointer;
        }
      }
    }
  }
}
