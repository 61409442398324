.table-view-modal-root {
  padding: 1em;
  .title-container {
    justify-content: space-between;
  }
  .input-container {
    justify-content: space-between;
    .input {
      width: 20%;
    }
  }
}
.table-views-modal-toolbar {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding-right: 1em;
  .searchbar-container {
    width: 17em;
    padding-bottom: 0.2em;
  }

  .create-button {
    height: 2.7em;
  }

  .delete-button {
    min-width: 0px;
    height: 2.8em;
    width: 4em;
  }
}
