@import "../../../theme/bryntumcustomtheme.scss";

.taskBoard {
  background-color: var(--tableBoardBkg);
  .b-taskboard-text {
    font-weight: 500;
    font-size: 14px;
    color: var(--tableTextColor);
  }

  .b-taskboard-card {
    min-height: 120px;
    border-radius: 8px;
    background-color: var(--tableBoardCardBackground);
    box-shadow: 0px 3px 3px -2px rgba(46, 129, 248, 0.1), 0px 3px 4px 0px rgba(46, 129, 248, 0.07),
      0px 1px 8px 0px rgba(46, 129, 248, 0.06);
  }

  .taskBoardCardSubtitle {
    @extend .b-taskboard-text;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.06px;
  }

  .b-taskboard-swimlane-body {
    gap: 0;
  }

  .b-taskboard-card-header {
    @extend .b-taskboard-text;
    padding: 12px;
    justify-content: space-between;
  }

  .b-taskboard-card-body {
    @extend .b-taskboard-text;
    padding: 12px;
    margin-top: -2.5px;
    font-weight: 300;
    font-size: 12px;
    line-height: 14.06px;
  }
  .b-taskboard-column-headers {
    gap: 0;
    max-height: 64px;
  }

  .b-taskboard-column-body-inner {
    padding: 10px 5px;
  }

  .b-taskboard-card-footer {
    padding: 12px;
    border-radius: 0 0 5px 5px;
  }

  .b-taskboard-column {
    &:not(:first-of-type) {
      border-left: 1px solid var(--Divider);
    }
    background-color: var(--tableBoardBkg);
    border-top: 1px solid var(--Divider);
  }

  .b-taskboard-column-header {
    &:not(:first-of-type) {
      border-left: 1px solid var(--Divider);
    }
    padding: 16px 0;
    background-color: var(--tableBoardBkg);
    button {
      color: var(--tableTextColor);
    }
  }

  .b-taskboard-column-title {
    @extend .b-taskboard-text;
    line-height: 16px;
    width: auto;
    color: var(--Primary);
  }

  .b-taskboard-column-count {
    @extend .b-taskboard-text;
    line-height: 16px;
  }

  .taskBoardToolbar {
    @extend .b-taskboard-text;
    font-weight: 700;
    line-height: 16.41px;
    letter-spacing: 0.5;
  }

  .b-taskboard-column-header-padder::before {
    content: "";
    background: currentColor;
    width: 1em;
    height: 1em;
    border-radius: 4px;
    margin-inline-end: -0.5em;
    align-self: center;
  }
}

.dark {
  .b-taskboard-card {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.1), 0px 3px 4px 0px rgba(0, 0, 0, 0.07),
      0px 1px 8px 0px rgba(0, 0, 0, 0.06);
  }
}
