.intable-editor {
  padding: 0.5em;
  max-width: 790px;
  /*width of rich text toolbar*/
}

.slate-editor {
  font-size: 14px;
  word-break: break-word;
  outline: none;
}

.popover-anchor {
  width: 100%;
}

.richtext-output {
  overflow: hidden;
}
