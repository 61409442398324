$card-width: 280px;

.MuiCard-root {
  &.content-card {
    &.clickable {
      cursor: pointer;
    }

    &.selected {
      cursor: pointer;
      border-image: transparent, linear-gradient(#f6b73c, #4d9f0c);
      border-width: 4px;
      border-style: solid;
      border-radius: 16px;
      background-origin: border-box;
      background-clip: content-box, border-box;
    }

    &.expanded {
      /*

      Removed to fix scroll of project content card in home carousel
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      */
      height: fit-content;
    }

    .associated-records {
      display: flex;
      .edison-typography {
        margin-left: 8px;
      }
    }

    .subheader-container {
      justify-content: space-between;
    }

    overflow: visible;
    width: $card-width;
    height: 380px;
    text-align: left;
    display: flex;
    flex-direction: column;

    .icon-image {
      width: 2.5em;
      height: 2.5em;
      margin-right: 0em;
    }

    .card-title-container {
      .MuiCardHeader-title {
        .MuiBox-root {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: flex;
        }
      }

      .MuiCardHeader-content {
        overflow-x: hidden;
      }
    }

    .card-media-badge {
      width: 100%;

      .MuiBadge-badge {
        margin-left: 3em;
      }
    }

    .description-box {
      width: 100%;
      overflow: hidden;
      flex-grow: 1;

      .edison-typography {
        white-space: normal;
      }
    }

    .icons-row {
      justify-content: space-between;
      padding: 0 0.5em 0.5em 0.5em;
      height: 56px;
    }

    .MuiPaper-root {
      &.expanded-content {
        z-index: 2;
        width: $card-width;
        margin-top: -2px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background-image: none;
        border-top: none;
        box-shadow: none;
        padding: 0;
        /*Needed to fix weird shifting of expanded card */
        margin-left: -1px;
      }
    }
  }
}

.content-card-popover-root {
  pointer-events: none;

  .MuiPopover-paper {
    width: $card-width;
    margin-top: -2px;
    border-radius: 0px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    box-shadow: 0px 3px 4px rgba(122, 176, 254, 0.07);

    pointer-events: auto;
  }

  .content-card-children-container {
    padding: 0 0.5em 0 0.5em;
  }
}
