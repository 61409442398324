$rowHighlight: #4c90f233;

:root {
  --ChipBackgroundError: #fbeaea;
  --ChipTextError: #541313;
  --Divider: #0000001f;
  --iconDisabled: #00000042;
  --InformationIconColor: #757575;
  --InnerDivider: #e0e0e0;
  --milestoneTaskColor: #a9a9a9;
  --ReadOnlyIconColor: #bdbdbd;
  --resourceUtilizationColor: #c2e4c3;
  --resourceUtilizationOverallocatedColor: #e57373;
  --rowSelected: #eaf4ff;
  --schedulerTaskColor: #dce8ff;
  --summaryTaskColor: #1976d2;
  --summaryTaskColor: #1976d280;
  --summaryTaskColorOpaque: #1976d280;
  --tableBackgroundColor: #fff;
  --tableBoardCardBackground: #fff;
  --tableHeaderBkg: #f8fbff;
  --tableBoardHeaderBkg: #f8fbff;
  --tableLoadingBackground: #1565c0;
  --tableLoadingText: #ffffff;
  --tableRowBackgroundColorEven: #ffffff;
  --tableRowBackgroundColorOdd: #f8fbff;
  --tableTextColor: rgba(0, 0, 0, 0.87);
  --tableWeeekendHeaderColor: #1186f133;
  --tableWeekendBodyColor: #1186f10a;
  --taskColor: #0d9588;
  --taskColor: #0d958880;
  --taskColorOpaque: #0d958880;
  --taskEditorMenuItemsColour: #616161;
}
@media (prefers-color-scheme: dark) {
  :root {
    --ChipBackgroundError: #541313;
    --ChipTextError: #fbeaea;
    --Divider: #ffffff1f;
    --InformationIconColor: #a5a7aa;
    --InnerDivider: #e0e0e0;
    --milestoneTaskColor: #a9a9a9;
    --ReadOnlyIconColor: #6f7278;
    --resourceUtilizationColor: #c2e4c3;
    --resourceUtilizationOverallocatedColor: #e57373;
    --rowSelected: #1b1d22;
    --summaryTaskColor: #1976d2;
    --summaryTaskColorOpaque: #1976d280;
    --tableBackgroundColor: #32363e;
    --tableBoardCardBackground: #32363e;
    --tableHeaderBkg: #373c41;
    --tableBoardBkg: #20232a;
    --tableLoadingBackground: #90caf9;
    --tableLoadingText: #000000de;
    --tableRowBackgroundColorEven: #363a42;
    --tableRowBackgroundColorOdd: #363a42;
    --tableTextColor: #e5e5e6;
    --tableWeeekendHeaderColor: #1186f133;
    --tableWeekendBodyColor: #1186f10a;
    --taskColor: #0d9588;
    --taskColorOpaque: #0d958880;
    --taskEditorMenuItemsColour: #fff;
  }
}

:export {
  rowHighlight: $rowHighlight;
}
