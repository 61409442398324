@import "../../../theme/bryntumcustomtheme.scss";

body:has(.fullscreen) {
  overflow-y: hidden;
}

.standard-table-container {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  margin: 0px auto;
}

.standard-table-root {
  border-radius: 8px;
  border: 2px groove var(--Divider);
  margin-top: 8px;
  width: 100%;

  .MuiSvgIcon-root {
    &.table-info-icon {
      color: var(--InformationIconColor);
    }

    &.table-readonly-icon {
      color: var(--ReadOnlyIconColor);
    }
  }

  &.fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: var(--tableRowBackgroundColorEven);
    margin-top: 0;
    height: 100%;
    overflow: auto;

    .standard-table-container {
      height: 90%;
    }
  }

  .grid-fullscreen.b-gridbase.b-outer {
    height: calc(100vh - 55px);
  }

  .sticky {
    position: sticky;
    top: 0;
    z-index: 2;
    background: inherit;
  }
}

.standard-table-visible {
  display: block;
  width: 100%;
}

.standard-table-hidden {
  display: none;
}
