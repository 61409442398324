.workflow-live-preview-root {
  .top-bar {
    padding: 10px 20px;

    .workflow-tab-container {
      justify-content: space-between;

      .single-tab {
        padding: 8px;
      }
    }
  }

  .tab-content-container {
    &.hidden {
      display: none;
    }

    .row-container {
      align-items: center;

      .row-content {
        padding: 10px 20px;
        min-width: 90%;
        flex-grow: 1;

        .row-title-container {
          justify-content: space-between;
        }

        .columns-container {
          max-height: 52vh;
          max-width: 100%;
          overflow-x: auto;

          .component-container {
            padding: 4px;
            border-radius: 5px;
            align-items: center;
          }
        }
      }
    }
  }
}