.resource-config-root {
  .hidden {
    display: none;
  }

  .resource-name-container {
    height: 100%;

    .people-group-container {
      justify-content: flex-end;
      height: 100%;
    }
  }
}
