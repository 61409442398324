.filterBadgeButton {
  z-index: 1;

  .iconButton {
    border: solid 1px #79abdd;
    border-radius: 10px;
    min-width: 0;
    height: 36px;
    width: 36px;
  }

  .iconButton.active {
    background-color: #daedff;
    z-index: -1;
  }
}

.filterWrapper {
  z-index: 1001;
  .popperContent {
    padding: 1em;
    max-width: 700px;
    min-width: 700px;

    box-shadow:
      0px 5px 6px -3px rgba(46, 129, 248, 0.2),
      0px 9px 12px 1px rgba(46, 129, 248, 0.14),
      0px 3px 16px 2px rgba(46, 129, 248, 0.12);
    border-radius: 12px;
  }
}
