.projects-discover-table-root {
  .name-column {
    :hover {
      cursor: pointer;
    }
  }

  .b-react-portal-container {
    width: 100%;
  }

  /* Gantt task custom styling */
  .b-gantt-task {
    border-radius: 12px;
    background-color: #8cbbe8;

    &:hover {
      border-radius: 12px;
      background-color: #8cbbe8;
    }

    &.b-task-selected {
      background-color: #8cbbe8;
    }

    .b-task-percent-bar {
      width: 0%;

      background-color: #1976d2;
    }
  }

  /*Handle gantt and grid background color */
  .b-widget.b-timeline {
    background-color: var(--tableBackgroundColor);

    .b-grid-panel-body {
      background-color: var(--tableBackgroundColor);

      .b-grid-row.b-hover {
        background-color: var(--tableBackgroundColor);
      }
    }
  }

  /* Handle custom colour of row when hovered or selected */
  .b-grid-body-container {
    .b-content-element.b-auto-container {
      .b-subgrid {
        .b-grid-row {
          &.b-hover {
            background-color: var(--tableRowBackgroundColorEven);
          }

          &.b-selected {
            background-color: var(--tableRowBackgroundColorEven);
          }
        }
      }
    }
  }

  /* Handle custom colour of row when hovered or selected */
  .b-gridbase:not(.b-moving-splitter)
    .b-grid-subgrid:not(.b-timeaxissubgrid)
    .b-grid-row:not(.b-group-row).b-hover {
    background-color: var(--tableRowBackgroundColorEven);
  }

  .b-grid-header-container .b-grid-headers,
  .b-timeaxis .b-sch-header-row,
  .b-grid-body-container,
  .b-grid-panel-body,
  .b-header .b-grid-headers .b-focused,
  .b-empty-text,
  .b-grid-footer,
  .b-grid-header-container .b-sch-timeaxiscolumn .b-sch-header-timeaxis-cell:hover,
  .b-grid-row.b-hover {
    box-shadow: none;
    background-color: var(--tableBackgroundColor);
  }

  /* Handle setting correct text and highlight color */
  .b-grid-subgrid .b-grid-row .b-grid-cell,
  .b-editor .b-field .b-field-inner,
  .b-grid-footer {
    color: var(--Primary);
  }

  .b-grid-header-text > .b-grid-header-text-content,
  .b-sch-header-timeaxis-cell,
  .b-gridbase:not(.b-masked) .b-grid-empty .b-grid-subgrid:first-child::before {
    color: var(--Primary);
  }
}
