.licenses-root {
  padding: 1em;

  .info-panel {
    padding: 1em;
    overflow-x: auto;

    .license-type-container {
      padding: 0.3em 1em;
      border-radius: 8px;
      font-weight: bolder;
      box-shadow:
        1px 3px 0px rgba(46, 129, 248, 0.04),
        0px 1px 1px 0px rgba(46, 129, 248, 0.04),
        0px 2px 1px -1px rgba(46, 129, 248, 0.06);
      text-transform: uppercase;

      &.Enterprise {
        background: var(--LicensesProfessionalColor);
        color: var(--LicensesProfessionalText);
      }

      &.Professional {
        background: var(--LicensesProfessionalColor);
        color: var(--LicensesProfessionalText);
      }

      &.Standard {
        background: var(--LicensesStandardColor);
        color: var(--LicensesStandardText);
      }

      &.Basic {
        background: var(--LicensesBasicColor);
        color: var(--LicensesBasicText);
      }
    }

    .top-container {
      justify-content: center;
    }
  }

  .search-container {
    width: 100%;
  }

  .MuiButtonBase-root {
    &.manage-license-button {
      text-wrap: nowrap;
    }
  }
}

.license-enum-select {
  width: 100%;
}
