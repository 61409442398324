.normal-stage-approval-root {
  padding-top: 1em;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  padding-top: 1em;

  .button-container {
    justify-content: flex-end;
  }

  .stage-canvas-container {
    height: 32em;
  }
}
