$primaryTextColor: var(--primary-text-color);
$primaryColor: var(--primary-color);
$adminColor30: var(--primary-color-30);
$secondaryColor: var(--secondary-color);
$secondaryTextColor: var(--secondary-text-color);

$ideasColor: var(--ideas-color);
$projectsColor: var(--projects-color);
$adminColor: var(--admin-color);
$casesColor: var(--cases-color);
$lightGrey: var(--light-grey-color);
$grey100: var(--grey-100-color);
$grey50: var(--grey-50-color);
$warning: var(--warning-color);
$white: var(--white-color);
$black: var(--black-color);
$dropColor: var(--drop-color);
$cardOutline: var(--card-outline);
$toolbarColor: var(--toolbar-color);

//dark mode
$darkPrimaryColor: var(--dark-primary-color);

$footer-height: 30;
$header-height: 60;
$drawer-width: 250;

$stageColor: #ffa048;
$reviewStageColor: #fe6565;
$conditionColor: #75b84e;

@mixin uppercase {
  text-transform: uppercase;
}

@mixin pointer {
  cursor: pointer;
}

$Main: var(--Main);
$Main2: var(--Main2);
$Contrast: var(--Contrast);
$Button2Background: var(--Button2Background);
$ButtonBackground: var(--ButtonBackground);
$Background: var(--Background);
$BackgroundBlock: var(--BackgroundBlock);
$No: var(--No);
$NoBackground: var(--NoBackground);
$Yes: var(--Yes);
$YesBackground: var(--YesBackground);
$Ideas: var(--Ideas);
$IdeasBackground: var(--IdeasBackground);
$BusinessCase: var(--BusinessCase);
$BusinessCaseBackground: var(--BusinessCaseBackground);
$Projects: var(--Projects);
$ProjectsBackground: var(--ProjectsBackground);
$PrimaryText: var(--PrimaryText);
$SecondaryText: var(--SecondaryText);
$Secondary2Text: var(--Secondary2Text);
$IconMain: var(--IconMain);
$DisabledText: var(--DisabledText);
$DividerText: var(--DividerText);
$DividerLine: var(--DividerLine);
$TableRow: var(--TableRow);
$TableSelect: var(--TableSelect);
$Currency: var(--Currency);
$Calculated: var(--Calculated);
$Url: var(--Url);
$Text: var(--Text);
$RichText: var(--RichText);
$RadioSingleChoice: var(--RadioSingleChoice);
$Percentage: var(--Percentage);
$PeopleOrGroup: var(--PeopleOrGroup);
$Number: var(--Number);
$MultiPeopleAndGroup: var(--MultiPeopleAndGroup);
$MultiLevelChoice: var(--MultiLevelChoice);
$MultipleChoice: var(--MultipleChoice);
$Location: var(--Location);
$Email: var(--Email);
$DateTime: var(--DateTime);
$Date: var(--Date);
$YesNo: var(--YesNo);
$ActionActive: var(--ActionActive);
$PaperColor: var(--PaperColor);
$Like: var(--Like);
$LikeHover: var(--LikeHover);
$EdisonGradient: var(--EdisonGradient);
$HistoryIconColor: var(--HistoryIconColor);
$SystemGradient: var(--SystemGradient);
$CustomAdminEntity: var(--CustomAdminEntity);
$ManagerLicenseGradient: var(--ManagerLicenseGradient);
$LicensesProfessionalColor: var(--LicensesProfessionalColor);
$LicensesProfessionalText: var(--LicensesProfessionalText);
$LicenseStandardColor: var(--LicenseStandardColor);
$LicenseStandardText: var(--LicenseStandardText);
$LicensesBasicColor: var(--LicensesBasicColor);
$LicensesBasicText: var(--LicensesBasicText);
$NavigationSelectedBgColor: var(--NavigationSelectedBgColor);
$NavigationSelectedColor: var(--NavigationSelectedColor);
$Error: var(--Error);
$InformationIconColor: var(--InformationIconColor);
$ReadOnlyIconColor: var(--ReadOnlyIconColor);
$ChipBackgroundError: var(--ChipBackgroundError);
$ChipTextError: var(--ChipTextError);
