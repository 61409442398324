.manage-licenses-root {
  min-width: 30em;
  padding: 1em;

  .top-container {
    justify-content: space-between;
    align-items: center;
  }
}

.license-menu-item {
  &.MuiStack-root {
    justify-content: space-between;
    width: 100%;
  }
}
