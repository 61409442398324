.manual-review-root {
  padding-top: 1em;
  height: 100%;
  justify-content: space-between;

  .button-container {
    justify-content: flex-end;
  }

  .stage-canvas-container {
    height: 22em;
  }

  .chevron-container {
    align-items: center;
  }
}
