.calculated-divider-root {
  width: 0.2em;
  height: 100%;
  padding: 1.1em 0;
  &.no-padding {
    padding: 0;
  }
  .divider-body {
    height: 100%;
    width: 0.2em;
    background-color: var(--Main);
    animation: blinker 1s linear infinite;
  }
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
}
