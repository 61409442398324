.project-table-row-review-root {
  height: 100%;
  justify-content: space-between;

  .button-container {
    justify-content: space-between;
  }

  .close-button {
    margin-left: auto;
  }
}
