@import "../../theme/colors.scss";

.MuiList-root {
  &.feature-list-container {
    position: relative;
    height: 350px;
    .nested-list {
      padding: 0;
      .sub-header {
        letter-spacing: 3px;
        background: $white;
        .icon-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
      .list-item {
        margin-left: 2em;
        cursor: grab;
        &:hover {
          color: $primaryColor;
        }
      }
    }
  }
}
