@import "../theme/colors.scss";

.nav-logo-spacing {
  .logo {
    transition:
      width ease-in 0.3s,
      height ease-in 0.3s;
    width: 180px;
    height: 45px;
  }

  .small-logo {
    transition:
      width ease-in 0.3s,
      height ease-in 0.3s;
    height: 45px;
    width: 45px;
  }
}

.exit-btn-root {
  padding-bottom: 20px;

  .exit-btn-icon {
    padding-left: 10px;
  }
}

.navigation-drawer {
  width: #{$drawer-width}px;
  flex-shrink: 0;
  white-space: nowrap;

  &::-webkit-scrollbar {
    width: 8px;
  }
}

.drawer-open {
  width: #{$drawer-width}px;
  transition: width ease-in 0.3s;
}

.drawer-close {
  transition: width ease-out 0.3s;
  width: 88px;
}

.module-route-menu-item-link {
  text-decoration: none;
  color: inherit;

  &.open {
    width: 100%;
  }
}

.module-route-menu-item {
  &.MuiMenuItem-root {
    border-radius: 5px;
  }
}

.route-menu-item-link {
  text-decoration: none;

  &.open {
    width: 100%;
  }
}

.route-menu-item {
  &.MuiMenuItem-root {
    border-radius: 5px;
    height: 32px;
  }

  .icon-container {
    height: 100%;
    display: flex;
    justify-content: center;
  }
}

.drawer-btn-chevron {
  &.MuiSvgIcon-root {
    height: 30px;
    width: 18px;
  }
}

.popper {
  width: 25%;
  z-index: 2;
  padding-left: 10px;
  filter: drop-shadow(-0.0625rem 0.0625rem rgba(76, 144, 242, 0.1));

  .create-popper {
    position: "relative";

    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      bottom: 82%;
      left: -1px;
      border-top: 10px solid transparent;
      border-right: 15px solid $PaperColor;
      border-bottom: 10px solid transparent;
    }
  }
}

.nav-create-btn-open {
  &.MuiButton-root {
    height: 36px;
    min-width: 36px;
    width: 220px;
    transition: width ease-in 0.3s;
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

.nav-create-btn-closed {
  &.MuiButton-root {
    height: 36px;
    min-width: 36px;
    width: 36px;
    transition: width ease-out 0.3s;
  }
}

.drawer-btn {
  &.MuiIconButton-root {
    margin-right: -14px;
    background-color: var(--Main);
    color: var(--Contrast);

    &:hover {
      background-color: var(--Main);
      color: var(--Contrast);
    }

    height: 26px;
    width: 26px;
  }
}
