.tabs {
  .tab-titles-container {
    width: 100%;

    .tabs-titles {
      width: 100%;
      > div > div.MuiTabs-flexContainer {
        height: 64px;
      }
      button.tab-title {
        margin: 0em 1em;
        padding: 0;
        font-size: 1rem;
        min-width: 0px;
        min-height: 20px;
        max-width: 470px;
        text-wrap: nowrap;
        > div > span {
          white-space: nowrap;
        }
        .tab-title-label {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
        }
      }
      .MuiTabs-indicator {
        bottom: initial;
        margin-top: -17px;
      }
    }
    .end-tab{
      cursor: pointer;
      justify-content: center;
      margin-bottom: 1em;
    }
  }

  .tab-content {
    box-sizing: border-box;
    padding: 2px 0px 10px 0px;
    min-height: 100px;
  }
}
