.table-view-modal-root {
  padding: 1em;
  .title-container {
    justify-content: space-between;
  }
  .input-container {
    justify-content: space-between;
    .input {
      width: 20%;
    }
  }
}
