.periodic-toolbar-container {
  .toolbarContainer {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 5px;
  }

  .leftContainer {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    column-gap: 10px;
    align-items: baseline;
  }
  .rightContainer {
    display: flex;
    column-gap: 10px;
    width: 100%;
    justify-content: flex-end;
  }
}
