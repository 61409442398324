.in-table.MuiAutocomplete-root {
  width: 100%;
}
.choice-list-item {
  padding: 2px;
  .list-stack {
    .list-checkbox {
      margin-right: 8px;
      margin-left: -2px;
      padding: 0px;
    }
  }
}
