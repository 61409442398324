body:has(.error-page-container){
  margin:0px;
}

.error-page-container {
  width:100%;
  min-height:100vh;
  background-color: var(--BackdropOverlay);
  color: var(--Primary);
}

.error-page-container .e365-logo{
 margin:54px;
}

.error-page-container .homepage-button > button {
  background: var(--Main);
  color: var(--tableLoadingText);
}
  
.error-page-container .homepage-button > button:hover {
  background: var(--Dark);
  text-decoration: none;
  background-color: #42A5F5;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.error-page-container .homepage-button > button svg {
  margin-right: 8px;
}