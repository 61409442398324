.people-config-root {
  .format-container {
    align-items: center;
  }
  .bounds-container {
    justify-content: space-between;
    .input {
      width: 44%;
    }
  }
  .multiple-values-container {
    align-items: center;
  }
}
