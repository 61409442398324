.read-only-wrapper-root {
  overflow: hidden;
  border-radius: 8px;
  border-color: var(--Divider);
  min-height: 78px;
  min-inline-size: 100%;

  &.required {
    border-color: var(--Error);
  }

  .required-icon {
    color: var(--Error);
    margin-right: 2px;
    margin-left: 2px;
  }

  &.allowed {
    &:hover {
      cursor: pointer;
      background-color: var(--rowSelected);
      box-shadow:
        0px 3px 3px -2px rgba(46, 129, 248, 0.1),
        0px 3px 4px rgba(46, 129, 248, 0.07),
        0px 1px 8px rgba(46, 129, 248, 0.06);
    }
  }
}
