@import "../../theme/colors.scss";

.edison-card {
  text-align: -webkit-center;
}
.card-live-preview-root {
  margin: -10px 0px -10px 20px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  .dropzone-visible {
    overflow: hidden;
    color: $black;
    background: $white;
    text-align: center;
    border: 2px dotted $lightGrey;
    border-radius: 5px;
    padding: 5px;
    margin: 20px;
    height: 40px;
  }
  .dropzone-visible:before {
    content: "Drop feature here to add";
    color: $lightGrey;
    margin: auto;
  }
}
