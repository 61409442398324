.single-table-root {
  &.fullscreen {
    width: 100%;
    overflow: hidden;
    .container {
      max-height: 81vh;
    }
  }
  .singletable-loading-row {
    .MuiTableCell-root {
      border-bottom: 0;
      &:first {
        padding-left: "27px";
        width: "5%";
      }
    }
  }
  .row-alert-cell.MuiTableCell-root {
    padding: 0;
  }
}
