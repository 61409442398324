.approval-table-row-root {
  &.expanded {
    background-color: var(--p12);
  }

  &.clickable {
    cursor: pointer;
  }

  .cell {
    &.MuiTableCell-root {
      padding: 6px;
    }
    .approval-link {
      color: var(--Main);
    }
  }

  .name {
    align-items: start;
  }

  .type {
    width: 10em;
    min-width: 10em;

    .MuiAvatar-root {
      color: var(--Contrast);
    }
  }

  .checklist {
    width: 14em;
    min-width: 14em;

    .checklist-container {
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }

  .stage {
    width: 16em;
    min-width: 16em;
    max-width: 16em;
  }

  .comments {
    width: 8em;
    min-width: 8em;
  }

  .status {
    width: 8em;
    min-width: 8em;
  }

  .action {
    width: 8em;
    min-width: 8em;
  }
}
