.switch-config-root {
  width: 100%;
  .format-container {
    align-items: center;
  }
  .bounds-container {
    justify-content: space-between;
    align-items: center;
    .input {
      margin-top: 10px;
      width: 31%;
    }
  }
}
