div.record-content-card.MuiGrid-item {
  padding-left: 15px;
  .card-field-root {
    padding: 0 1em;
    margin-bottom: 1em;

    .row-container {
      width: 100%;
    }
  }

  .bc-timer-icon {
    width: 16px;
    height: 16px;
    margin-top: 4px;
    margin-right: 5px;
  }
}
