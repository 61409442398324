.colour-picker-popover {
  .inner {
    width: 220px;
    padding: 10px;
    .chip {
      &.selected {
        .MuiChip-label {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
}
