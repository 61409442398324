.workflow-designer-page-root {
  height: 89vh;

  .left-panel {
    padding: 1em;
    width: 25%;
    border-radius: 10px;
    overflow-y: scroll;
  }

  .right-panel {
    width: 75%;
    height: 85vh;
  }
}
