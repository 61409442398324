.workflow-live-preview-column-root {
  width: 100%;
  flex-grow: 1;

  .component-container {
    padding: 4px;
    border-radius: 5px;
    align-items: center;
  }
}
