@import "../../../theme/bryntumcustomtheme.scss";

body:has(.fullscreen) {
  overflow-y: hidden;
}

.task-table-root {
  .b-react-portal-container {
    width: 100%;
    min-height: 100%;
    display: flex;
  }

  width: 100%;
  margin-top: 8px;
  border-radius: 8px;
  border: 2px groove var(--Divider);

  .MuiSvgIcon-root {
    &.table-info-icon {
      color: var(--InformationIconColor);
    }

    &.table-readonly-icon {
      color: var(--ReadOnlyIconColor);
    }
  }

  &.fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: var(--tableRowBackgroundColorEven);
    margin-top: 0;
    height: 100%;
    overflow: auto;

    .task-view {
      height: 94%;

      .task-table-container {
        height: 100%;
      }
    }
  }

  .gantt-fullscreen {
    height: calc(100vh - 55px);
  }

  .sticky-toolbar {
    padding-top: 0.5em;
    position: sticky;
    top: 0;
    z-index: 2;
    background: inherit;
  }

  .task-view {
    display: none;

    &.visible {
      display: block;
    }
  }

  .task-table-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;

    /*margin: 0px auto;*/
    .b-mask {
      min-height: 100px;
    }

    .b-mask-content {
      top: 50px;
      height: 56px;
      color: var(--tableLoadingText);
    }

    .b-mask-content,
    .b-mask-text {
      background-color: var(--tableLoadingBackground);
      font-family: "Open Sans", sans-serif;
    }

    .b-mask-text {
      padding: 5px 17px 5px 11px;
    }

    .b-icon-spinner {
      display: none;
    }

    .loaderMaskLogo {
      background: url(../../../assets/loading-webp.webp) top center no-repeat fixed transparent;
      background-size: cover;
      width: 41px;
      height: 41px;
      margin-right: 8px;
    }

    div.b-grid-splitter {
      display: block;
    }

    .b-task-baseline {
      border-radius: 30px;
      background-color: #00000099;
    }

    .b-hide-baseline {
      display: none;
    }

    .b-gantt-task {
      height: 24px;
    }

    /* Override default milestone colour*/
    .b-gantt-task.b-milestone .b-gantt-task-content,
    .b-gantt-task.b-milestone:hover .b-gantt-task-content {
      background-color: var(--milestoneTaskColor);
    }

    /* Override default segment styling to show a grey bar between segments*/
    .b-sch-event-segments {
      height: 100%;
      border-bottom: none;
      background: rgba(187, 187, 187, 0.2509803922);
      border-radius: 30px;
    }

    /* Used to override the the task bar styling in the gantt*/

    /* Does override milestone default colour. Also removes default task colour if segmented because the segmment itself has colour allready.*/
    /* Overrides default segments colour*/
    .b-gantt-task:not(.b-milestone):not(.b-segmented),
    .b-gantt-task:hover:not(.b-milestone):not(.b-segmented),
    .b-task-selected:not(.b-milestone):not(.b-segmented),
    .b-sch-event-segments .b-sch-event-segment,
    .b-gantt-task.b-segmented:hover .b-sch-event-segments .b-sch-event-segment {
      background-color: var(--taskColorOpaque);
      border-color: var(--taskColorOpaque);
      border-radius: 30px;

      .b-task-percent-bar {
        background-color: var(--taskColor);
        border-color: var(--taskColor);
      }
    }

    .b-gantt-task-parent {
      .b-gantt-task:not(.b-milestone),
      .b-gantt-task:hover:not(.b-milestone),
      .b-task-selected:not(.b-milestone) {
        background-color: var(--summaryTaskColorOpaque);
        border-color: var(--summaryTaskColorOpaque);
        border-radius: 30px;
        clip-path: none;

        .b-task-percent-bar {
          background-color: var(--summaryTaskColor);
          border-color: var(--summaryTaskColor);
        }
      }
    }

    .b-task-percent-bar-outer {
      border-radius: 30px;
    }

    /* Increasing the min height for tasks to 0.6em in gantt when there are baselines*/
    $task-with-baselines-height: 0.6em;

    .b-baselines .b-gantt-task-wrap.b-has-baselines {
      height: $task-with-baselines-height;

      .b-sch-event-segment {
        height: 100% !important;
      }
    }

    .b-baseline-wrap {
      top: $task-with-baselines-height;
    }

    /* Set the color of critical tasks in the gantt when show critical paths has been toggled*/
    /* Added .b-task-percent-bar-outer selector to increase the needed specificity of style*/
    .b-ganttbase.b-gantt-critical-paths .b-gantt-task.b-critical,
    .b-ganttbase.b-gantt-critical-paths
      .b-gantt-task.b-critical
      .b-task-percent-bar-outer
      .b-task-percent-bar,
    .b-ganttbase.b-gantt-critical-paths .b-gantt-task.b-critical .b-sch-event-segment:hover,
    .b-ganttbase.b-gantt-critical-paths .b-gantt-task.b-critical .b-sch-event-segment {
      background-color: #db5643;
    }

    .b-gantt-critical-paths .b-grid-subgrid-locked .b-grid-row.b-critical .b-grid-cell {
      background-color: #ff000026 !important;
    }
  }

  /*Task Table Timeline Styling */
  .b-sch-style-plain.b-sch-color-gantt-green > .b-sch-event:not(.b-milestone),
  .b-sch-style-plain.b-sch-color-gantt-green > .b-sch-event:not(.b-milestone):hover {
    background-color: var(--taskColor);
    border-color: var(--taskColor);
    border-radius: 30px;
  }

  .b-widget.b-timeline {
    flex: none;
    height: 7em;
    border-radius: 10px;
    background-color: var(--tableBackgroundColor);

    .b-grid-panel-body {
      padding: 0em 6em 2em 6em;
      background-color: var(--tableBackgroundColor);
      border-radius: 10px;
    }

    label::before {
      display: none;
    }

    .b-widget-cell {
      border-radius: 30px;
      margin: 1px;
    }

    .b-timeline-startdate,
    .b-timeline-enddate {
      font-size: small;
    }

    .b-sch-header-text {
      font-size: small;
    }
  }
}

/* Styling the assignment picker which a dialog so its sits outside of the task table tree*/
.b-assignmentpicker-body-wrap {
  background-color: var(--tableBackgroundColor);

  .b-grid-panel-body,
  .b-dock-bottom.b-bottom-toolbar,
  .b-grid-header-container,
  .b-grid-row.b-selected,
  .b-grid-row,
  .b-grid-cell.b-checkbox-selection,

  /* Need a specificity of at least 0,8,0 to overrided the default bryntum styling of the assignment rows */
  /* selected rows*/
  &.b-panel-body-wrap .b-grid-panel-body .b-grid-body-container .b-grid-subgrid:not(.b-timeaxissubgrid) .b-grid-row:not(.b-group-row).b-hover.b-selected,
  /*non selected rows */
  &.b-panel-body-wrap .b-grid-panel-body .b-grid-body-container .b-grid-subgrid:not(.b-timeaxissubgrid) .b-grid-row:not(.b-group-row).b-hover,
  /*checkbox styling */
  &.b-panel-body-wrap .b-grid-panel-body .b-grid-body-container .b-grid-subgrid:not(.b-timeaxissubgrid) .b-grid-row:not(.b-group-row).b-hover .b-grid-cell.b-checkbox-selection,
  .b-grid-row.b-selected .b-grid-cell.b-checkbox-selection {
    background-color: var(--tableBackgroundColor);
  }

  .b-grid-cell,
  .b-filter-bar-field-input {
    color: var(--tableTextColor);
  }
}

/*Styling time  picker which is a dialog so it sits outside of the task table tree*/
.b-timepicker {
  &.b-content-element {
    background-color: var(--tableBackgroundColor);
    color: var(--tableTextColor);
  }
}

/*Styling date picker which is a dialog so it sits outside of the task table tree*/
.b-calendarpanel.b-datepicker {
  /* Day picker styling */
  .b-datepicker .b-selected-date .b-icon-circle,
  .b-panel-content.b-calendarpanel-content,
  .b-panel-content.b-calendarpanel-content .b-calendar-weekdays {
    background-color: var(--tableBackgroundColor);
    color: var(--tableTextColor);
  }

  /* Month toolbar styling*/
  .b-toolbar.b-dock-top .b-datepicker-title,
  .b-toolbar.b-dock-top .b-datepicker-title .b-field,
  .b-field {
    color: var(--tableTextColor);
  }

  .b-calendar-cell.b-active-date:not(.b-selected-date) > .b-datepicker-cell-inner {
    background-color: var(--milestoneTaskColor);
  }

  .b-toolbar,
  .b-box-center.b-toolbar-content {
    background-color: var(--tableBackgroundColor);
  }

  /* Clearing default bryntum animation because it caused flashing of white in dark mode */
  @keyframes b-slide-in-from-right {
    30% {
    }

    30.01% {
    }

    100% {
    }
  }

  @keyframes b-slide-in-from-left {
    30% {
    }

    30.01% {
    }

    100% {
    }
  }
}

/*Styling time picker which is a dialog so it sits outside of the task table tree*/
.b-container.b-timepicker {
  .b-panel-content.b-timepicker-content {
    background-color: var(--tableBackgroundColor);

    .b-numberfield {
      .b-field-inner {
        color: var(--tableTextColor);
      }
    }
  }
}
