.record-delete-modal-root {
  .MuiAlert-root {
    &.top-delete-alert {
      padding-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .MuiAlert-message {
        margin-left: 32px;
        padding-bottom: 0;
      }
    }

    &.bottom-delete-alert {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding-top: 0;
    }
  }
}
