.dashboard-items-table-root {
  .overdue {
    &.MuiTableCell-root {
      color: var(--p160);
    }
  }
  .dashboard-header {
    .MuiTableCell-root {
      font-weight: 700;
      background-color: var(--Contrast);
    }
  }
}
