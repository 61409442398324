@import "../../theme/bryntumcustomtheme.scss";

.choice-editor-root {
  width: 100%;

  .choice-editor-toolbar {
    width: 100%;
    justify-content: flex-end;
    align-items: center;

    .searchbar-container {
      max-width: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }

    .button {
      height: 32px;
      min-width: 36px;
    }
  }

  .choice-editor-gantt {
    width: 100%;

    .b-react-portal-container {
      display: flex;
      align-items: center;
    }
  }
}
