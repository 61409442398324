@import "../../../theme/bryntumcustomtheme.scss";

body:has(.fullscreen) {
  overflow-y: hidden;
}

.periodic-table-root {
  border-radius: 8px;
  border: 2px groove var(--Divider);
  margin-top: 8px;

  .MuiSvgIcon-root {
    &.table-info-icon {
      color: var(--InformationIconColor);
    }

    &.table-readonly-icon {
      color: var(--ReadOnlyIconColor);
    }
  }

  &.fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: var(--tableRowBackgroundColorEven);
    margin-top: 0;
    height: 100%;
    overflow: auto;
  }

  .grid-fullscreen.b-gridbase.b-outer {
    height: calc(100vh - 55px - 44px);
  }

  .sticky-toolbar {
    padding-top: 0.5em;
    position: sticky;
    top: 0;
    z-index: 2;
    background: inherit;
    opacity: 1;
  }

  .periodic-table-container {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    margin: 0px auto;
  }

  .periodic-table-visible {
    display: block;
    width: 100%;
  }

  .periodic-table-hidden {
    display: none;
  }
}
