.workflowzone-root {
  height: 100%;

  .react-flow-container {
    height: 100%;
    width: 100%;
    justify-content: space-between;

    .custom-controls {
      width: 100%;
      height: 100%;

      justify-content: space-between;

      .buttons-container {
        justify-content: space-between;
        height: 80%;

        .layout-button {
          z-index: 5;
        }

        .option-buttons-container {
          width: fit-content;
          height: 100%;

          .react-flow-button {
            z-index: 5;
            border-radius: 100%;
            min-width: 3em;
            width: 3.5em;
            height: 3.5em;
          }

          .nodes-container {
            width: 100%;

            &.hidden {
              display: none;
            }

            .node-button {
              z-index: 5;
              border-radius: 100%;
              width: fit-content;
              width: 4em;
              height: 4em;
            }
          }
        }
      }

      .save-button-container {
        height: fit-content;

        .save-button {
          z-index: 5;
        }
      }
    }
  }
}
