@import "../../theme/colors.scss";

// TODO: Add paper background color from them
// TODO: Add title styling from theme.typography
.livepreview {
  .livepreview_title {
    display: flex;
    justify-content: space-between;

    .lpviewport {
      @include pointer;
    }
  }

  .paper {
    margin-top: 10px;
    background-color: $grey50;
    height: 90%;
  }
  .title {
    // TODO: Add title styling from theme.typography=> title: {...theme.typography.h5,marginBottom: "5px",},
    margin-bottom: 5px;
  }
  .lp_paper {
    background-color: $grey100;
    height: 90%;
  }
  .livepreview_body {
    height: 100%;
    width: 100%;
  }
  .base {
    background-color: $white;
    cursor: move;
  }

  .pageContainer {
    display: flex;
    flex: 1;
    flex-direction: column;

    .preview_add {
      cursor: pointer;
      margin: 10px;
    }
  }

  .page {
    flex: 1 1 auto;
    background-color: $white;
    border-radius: 4px;
    padding: 10px 0px;

    .lp_page-title {
      display: flex;
      margin-left: 5px;

      p:nth-child(1) {
        margin-right: 15px;
      }
    }
  }

  .livepreview_add-row {
    margin-top: 10px;
  }
}
