.news-card-root {
  height: 20.625em;
  width: 20em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.MuiPaper-root {
    background-image: none;
  }

  &.double {
    width: 40.625em;
  }

  .news-image {
    border-radius: 8px;
    height: 11em;
    object-fit: cover;
    width: 100%;
  }

  .news-card-container {
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
    padding: 0 1em;
    display: flex;

    .news-description {
      line-height: 1.5em;
      height: 3em;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      width: 100%;
    }
  }

  .news-actions {
    margin-top: auto;
    justify-content: space-between;
  }
}
