@mixin container-padding {
  padding: 1em {
    bottom: 1.5em;
  }
}
.config-layout-root {
  .top-panel-container {
    @include container-padding;
    .title {
      justify-content: space-between;
    }
    .content {
      justify-content: space-between;
    }
  }
  .bottom-panel-container {
    @include container-padding;
  }
}
