.events-container {
  padding: 1em;
  padding-bottom: 0;

  .navigation-container {
    justify-content: flex-end;
    margin-top: 10px;
  }

  .title-container {
    position: absolute;
    justify-content: space-between;
    align-items: center;
  }
}
