.create-record-select-challenge-root {
  .title-container {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  /* needed to overwrite the default behaviour of the content card, 
       allow the description to fill the full length of card */
  .extended-description {
    :nth-child(3) {
      height: 45%;

      .edison-typography {
        overflow-y: visible;
        -webkit-line-clamp: 7;
        height: -webkit-fill-available;
      }
    }
  }
}
