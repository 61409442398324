.report-wrapper {
  .no-data-container {
    padding: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.17px;
  }

  //b-resource-avatar b-resource-initials b-sch-black
  div.b-resource-initials {
    margin-right: 20px;
  }
}
