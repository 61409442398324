.tasks-row-root {
  .cell {
    &.MuiTableCell-root {
      padding: 6px;
    }

    .tasks-link {
      color: var(--Main);
    }
  }

  .description {
    width: 14em;
    min-width: 14em;
  }

  .card {
    width: 14em;
    min-width: 14em;
  }

  .end-date {
    width: 10em;
    min-width: 10em;
  }

  .percentage-complete {
    width: 8em;
    min-width: 8em;
  }

  .effort {
    width: 10em;
    min-width: 10em;
  }

  .action {
    width: 8em;
    min-width: 8em;
  }
}
