.tasks-row-dialog-root {
  min-height: 20em;
  .loading-container {
    min-width: 15em;
    min-height: 20em;
    justify-content: center;
    align-items: center;
  }

  .title-container {
    justify-content: space-between;
    align-items: center;
  }

  .save-button-container {
    justify-content: space-between;
    width: 100%;
  }
}
