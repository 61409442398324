.report-root {
  height: 100%;
  display: flex;
  flex-direction: column;

  .toolbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-top: 2px solid #e2e2e2;
    border-bottom: 2px solid #e2e2e2;

    .actionButton {
      .icon-button {
        z-index: 1;
        border: solid 1px #79abdd;
        border-radius: 10px;
        margin-left: 5px;
      }
    }
  }
}

mgt-get {
  margin-left: 0px !important;
}

mgt-person {
  margin-right: 5px;
}

mgt-person.avatar-large {
  --avatar-size: 40px;
}
