@import "../../theme/bryntumcustomtheme.scss";

.resource-utilization-container {
  .b-resource-avatar {
    margin-right: 10px;
  }

  div.b-histogram {
    svg {
      g {
        text.b-bar-legend {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 143%;
          letter-spacing: 0.17px;
          color: rgba(0, 0, 0, 0.87);
        }
      }
    }
  }
}
