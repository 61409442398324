.user-profile-wrapper {
  padding: 20px;

  .user-profile-header {
    margin-bottom: 20px;
  }

  .user-profile-contact {
    margin-top: 10px;
    height: 50px;
    padding: 10px 0;
    column-gap: 20px;
    align-items: center;

    .user-profile-single-contact {
      display: flex;
      align-items: center;
      column-gap: 8px;
      color: --var(tableTextColor);
    }
  }

  .user-profile-details {
    padding: 20px 0;
    column-gap: 40px;
    align-items: center;

    .user-profile-single-details {
      display: flex;
      align-items: center;
      column-gap: 12px;

      .user-profile-single-details-title {
        font-size: 14px;
        font-weight: 400;
        color: --var(tableTextColor);
      }
      .user-profile-single-details-subtitle {
        font-size: 16px;
        font-weight: 700;
        color: --var(tableTextColor);
      }
    }
  }

  mgt-person {
    --avatar-size: 80px;
    --font-size: 20px;
    --font-weight: 600;
    --line2-font-size: 16px;
    --line2-font-weight: 400;
    --details-spacing: 20px;
    --color: --var(tableTextColor);
    --line2-color: --var(tableTextColor);
  }
}
