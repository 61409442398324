.workflow-option-container {
  cursor: grab;
  z-index: 5;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-around;

  .option-button-background {
    background-color: white;
    border-radius: 100%;
    .option-button {
      background-color: white;
      border-radius: 100%;
      min-width: 3em;
      width: 3em;
      height: 3em;
    }
  }
}
