.expression-builder-root {
  .options-container {
    justify-content: space-around;
    align-items: center;

    .calculated-select {
      width: 28%;

      &.functions {
        fieldset {
          border-color: var(--CFFunctions);
        }
      }

      &.fields {
        fieldset {
          border-color: var(--CFFields);
        }
      }

      &.operators {
        fieldset {
          border-color: var(--CFOperators);
        }
      }
    }
  }

  .operator {
    &.MuiButton-root {
      min-width: 3em;
      font-weight: bolder;

      &.custom {
        color: var(--SuccessMain);
        border-color: var(--SuccessMain);
      }
    }
  }

  .expression-zone-container {
    padding: 1em;
    background-color: var(--BackdropOverlay);
    .equals-container {
      font-size: x-large;
      padding: 1em;
    }
  }
}
