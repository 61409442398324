.home-page-root {
  $var: 74;
  row-gap: 1em;

  //as we add more carousels to the home page, we need to increase the final number in the "through" part.
  //For example, when we add the businessCase caorusel, should be "through 3"
  //This will move each  arrow set child 431 pixels down to max the placement of the caorusel

  @for $i from 1 through 2 {
    :nth-child(#{$i}) .events-container .navigation-container {
      top: #{$var}px;
      right: 52px;
      $var: $var + 431;
    }
  }

  .events-container {
    padding: 1em;
    padding-bottom: 0;

    .navigation-container {
      justify-content: flex-end;
    }

    .title-container {
      position: absolute;
      justify-content: space-between;
      align-items: center;
    }
  }
}
