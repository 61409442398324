.collapsible-list-root {
  .item-icon-header {
    justify-content: center;
  }

  .item-text-header {
    margin-bottom: "2px";
  }
  .item-icon-header {
    margin-right: -1;
    transition: "0.2s";
  }

  .item-container {
    padding-top: 1;
    min-height: 32;
    width: 100%;
    padding-left: 70px;

    .item-icon {
      display: flex;
      justify-content: end;
      margin-right: -1;
    }
  }
  .searchbar-container {
  }
}
