.e365-logo {
  height: 41px;
  width: 175px;
}
.error-page {
  .unauthorized-logo {
    height: 293px;
    width: 231px;
  }

  .no-tenant-logo {
    height: 392px;
    width: 746px;
  }

  .something-wrong-logo {
    height: 392px;
    width: 746px;
  }

  .description {
    width: 35%;
    text-align: center;
    padding-top: 20px;

    & > span {
      white-space: normal;
    }
  }

  .homepage-button {
    padding-top: 20px;
  }
}
