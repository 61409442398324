.resource-fields-root {
  width: 100%;
  .field-selector {
    .fields-list {
      // height: 40em;
      max-height: 100%;
      overflow-y: auto;
    }

    .MuiInputBase-formControl.input {
      width: 100%;
    }

    width: 20em;
  }

  .selected-fields {
    flex-grow: 1;
  }
}
