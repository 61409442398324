.edison-people-picker-popper-root {
  z-index: 500;

  .MuiAutocomplete-listbox {
    padding-top: 0;
  }

  .MuiListSubheader-root {
    padding: {
      top: 10px;
      bottom: 6px;
    }
  }
}

.avatar-group-root {
  width: fit-content;
}

.edison-people-picker-root,
.popover-anchor {
  height: 100%;

  &.in-table {
    height: 45px;
  }

  &.readonly-not-in-table {
    min-height: 24px;
  }
}

.MuiPopover-root {
  padding: 5px;
}

mgt-people-picker {
  --input-border: none;
  --input-border-bottom: 1px solid;
  --input-background-color: transparent;
}
