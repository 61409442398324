.multi-level-choice-field-root {
  &.in-table {
    min-height: 45px;
    width: 100%;

    .MuiAutocomplete-root {
      width: auto;
    }
  }

  .in-table {
    width: 100vw;
  }

  .list-item {
    padding-top: 2px;
    padding-bottom: 2px;

    .list-stack {
      margin-left: -7px;

      .list-checkbox {
        margin-right: 8px;
        margin-left: -2px;
        padding: 0px;
      }
    }
  }
}
