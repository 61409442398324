@import "../../theme/colors.scss";

.edison-header {
  i.edison-gradient {
    font-size: 18px;
    background: -moz-linear-gradient(var(--EdisonGradient));
    background: -webkit-linear-gradient(var(--EdisonGradient));
    background: linear-gradient(var(--EdisonGradient));
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  i.edison-action {
    font-size: 18px;
    color: var(--ActionActive);
  }
  .project-editor {
    .MuiBox-root {
      margin: 0px;
    }

    .edison-action {
      color: var(--primary);
    }

    mgt-person {
      --avatar-border-radius: 70px;
      --avatar-border: 1px solid transparent;
      --person-avatar-size: 32px;
      background: linear-gradient(var(--EdisonGradient));
      border-radius: 100%;
      position: relative;
      text-align: center;
    }
    mgt-person:before {
      border-radius: 100%;
      content: "";
      background-image: linear-gradient(var(--EdisonGradient));
      top: -1px;
      left: -1px;
      bottom: -1px;
      right: -1px;
      position: absolute;
      z-index: -1;
    }
  }

  .project-viewers {
    .MuiBox-root {
      margin: 0px;
    }
  }
  mgt-person {
    --avatar-size: 32px;
    --font-size: 20px;
    --font-weight: 600;
  }

  mgt-people {
    --list-margin: 8px 0 8px 8px;
    --avatar-margin: 0 5px 0 0;
    margin: 0px;
  }
}
