.manager-override-root {
  margin-top: 1em;

  .button-container {
    justify-content: flex-end;
  }
  .approved-progress-container {
    align-items: center;
    .MuiLinearProgress-root {
      width: 15em;
      background-color: var(--LikeHover);
      .MuiLinearProgress-bar {
        background-color: var(--SuccessMain);
      }
    }
  }
}
