.condition-expression-root {
  min-height: 3em;
  align-items: center;

  .capitalized {
    color: var(--CFFunctions);
    text-transform: uppercase;
  }

  .expression-container {
    width: 100%;
    border-radius: 2px;

    &.selected {
      box-shadow: 0 0 0 1pt lightblue;
    }

    .expression {
      width: 100%;
      cursor: pointer;

      .end-dropzone {
        flex-grow: 1;
      }
    }

    .delete-button {
      height: fit-content;

      .cancel-icon {
        color: var(--MainContrast);
      }
    }
  }
}
