@import "../../theme/colors.scss";

.dropzone {
  flex: 0 0 auto;
  height: 25px;
  transition: 200ms all;

  .hr-theme-slash-2 {
    display: flex;
    &.horizontalDrag {
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
    }

    .hr-line {
      width: 100%;
      position: relative;
      margin: 13px 5px;
      &.horizontalDrag {
        height: 100%;
        margin: 0px 0px 0px 9px;
      }
    }
    .hr-icon {
      position: relative;
      top: 3px;
    }
  }

  &.horizontalDrag {
    width: 20px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &:not(.horizontalDrag).isLast {
    flex: 1 1 auto;
  }

  &.active {
    color: $black;
    background: $dropColor;
    transition: 100ms all;
  }

  &.active:before {
    color: $lightGrey;
    margin: auto;
  }
}
