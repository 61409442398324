.infinite-scroll {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;

  .disabled-project {
    opacity: 0.5;
  }
}

.projects-discover-root {
  .toolbar-container {
    .searchbar-container {
      width: 15em;
    }

    .filter-chip {
      &.MuiChip-root {
        height: 36px;
      }
    }
  }

  .view-selector {
    width: 12em;

    .MuiSelect-select {
      display: flex;
    }
  }

  .noResultMessage {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.17px;
    color: var(--tableTextColor);
  }

  .loading-container {
    width: 100%;
    align-items: center;
  }
}
