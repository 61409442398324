.rollup-table-root {
  padding-top: 0.4em;

  .sticky-header {
    position: sticky;
    top: 0;
    z-index: 2;
    background: inherit;
  }

  .toolbar {
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding-right: 1em;
    min-height: 48px;

    .searchbar-container {
      width: 17em;
      padding-bottom: 0.2em;
    }

    .create-button {
      height: 2.7em;
    }
  }
}

.fullscreen-table-dialog {
  .MuiDialog-paperScrollPaper {
    overflow-y: hidden;
  }
}

.rollup-table-tab-title {
  .text-container {
    height: 100%;
    display: flex;
    align-items: center;
  }
}
