.create-record-created-root {
  .created-container {
    justify-content: center;
    align-items: center;
  }

  .copied-values-container {
    max-height: 20em;
    overflow-y: auto;

    .copied-value-row {
      justify-content: space-between;
    }
  }

  .button-container {
    justify-content: space-between;
  }
}
