.expandable-menu-root {
  width: 100%;

  .expandable-menu {
    &.sticky {
      position: sticky;
      top: 0;
    }

    &.no-side-padding {
      padding-left: 0;
      padding-right: 0;
    }

    .expandable-menu_header {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      align-items: center;
    }
  }
}
