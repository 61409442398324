.stage-events-root {
  .expandable-menu-container {
    align-items: flex-start;

    .version-options-container {
      padding: 1em;
      align-items: center;
    }

    .prepopulated-options-container {
      padding: 1em;
      .configure-button {
        width: 100%;
      }
      .always-occur-container {
        align-items: center;
      }
    }
  }
}

.stage-events-popover-root {
  padding: 1em;
}
