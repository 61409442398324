@import "../../theme/colors.scss";

.popper-share {
  width: 240px;
  filter: drop-shadow(-0.0625rem 0.0625rem rgba(76, 144, 242, 0.1));

  .share-popper {
    position: "relative";

    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      bottom: 100%;
      left: 45%;
      border: 0.75rem solid transparent;
      border-top: none;
      border-bottom-color: $PaperColor;
      filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
    }
  }
}
