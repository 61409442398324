.type-config-top-container {
  width: 100%;
  justify-content: space-between;
}
.bottom-container {
  width: 100%;

  .right-container {
    width: 50%;
  }

  .image-wrapper {
    min-width: 50%;
  }

  .imageField {
    .dropzone {
      min-height: 17em;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
